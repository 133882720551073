import { FC, ReactNode } from "react";

import { FormConfigItem, FormItemType } from "../../types/form";

import { Paragraph, Title } from "@smartsuite/react-ui/lib";
import { FieldVisibilityCallback } from "../../hooks/useFormFieldVisibility";
import { $t } from "../../utils/intl";
import "./FormSectionItem.sass";
import { FormSectionItemText } from "./FormSectionItem.text";

export interface FormSectionItemProps {
  item: FormConfigItem;
  checkFieldVisibility: FieldVisibilityCallback;
  renderFormFieldItem: (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ) => ReactNode;
  renderFormHTMLBlockItem: (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ) => ReactNode;
  renderFormImageItem: (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ) => ReactNode;
  renderFormVideoItem: (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ) => ReactNode;
  renderFormCalloutItem: (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ) => ReactNode;
  renderFormDividerItem: (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ) => ReactNode;
  renderFormRecaptchaItem: (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ) => ReactNode;
  renderFormConsentItem: (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ) => ReactNode;
}

export const FormSectionItem: FC<FormSectionItemProps> = ({
  item,
  checkFieldVisibility,
  renderFormFieldItem,
  renderFormHTMLBlockItem,
  renderFormImageItem,
  renderFormVideoItem,
  renderFormCalloutItem,
  renderFormDividerItem,
  renderFormRecaptchaItem,
  renderFormConsentItem,
}) => {
  // Check if the section has and conditions configured and enabled, and if the condition
  // is satisfied. If so, renders its title and caption. The fields that live in the
  // section are processed outside of this component, and rendered flat with the rest of
  // the form items, but using the same conditions as the section.
  const isItemConditionSatisfied = checkFieldVisibility(item.slug);
  if (!isItemConditionSatisfied) return null;

  const sectionLabel = item.params.label ? item.params.label : $t(FormSectionItemText.section);
  const sectionCaption = item.params.caption;
  const sectionItems = item.params.items;

  return (
    <div className="form-section-item">
      <div className="form-section-item__header-wrapper">
        <label className="form-section-item__label">
          <Title size="xl" color="primary" weight="semibold">
            {sectionLabel}
          </Title>
        </label>
        {sectionCaption && (
          <div className="form-section-item__caption">
            <Paragraph size="s" color="secondary">
              {sectionCaption}
            </Paragraph>
          </div>
        )}
      </div>
      {!sectionItems?.length ? null : (
        <div className="form-section-item__items-wrapper">
          {sectionItems.map((item) => {
            if (item.type === FormItemType.html_block)
              return renderFormHTMLBlockItem(item, checkFieldVisibility);
            if (item.type === FormItemType.field)
              return renderFormFieldItem(item, checkFieldVisibility);
            if (item.type === FormItemType.image)
              return renderFormImageItem(item, checkFieldVisibility);
            if (item.type === FormItemType.video)
              return renderFormVideoItem(item, checkFieldVisibility);
            if (item.type === FormItemType.callout)
              return renderFormCalloutItem(item, checkFieldVisibility);
            if (item.type === FormItemType.divider)
              return renderFormDividerItem(item, checkFieldVisibility);
            if (item.type === FormItemType.recaptcha) {
              return renderFormRecaptchaItem(item, checkFieldVisibility);
            }
            if (item.type === FormItemType.consent) {
              return renderFormConsentItem(item, checkFieldVisibility);
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};
