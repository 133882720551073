import { FormikValues } from "formik";
import { useCallback, useMemo } from "react";
import { cleanFilterRules, isConditionSatisfiedMemoized } from "../helpers/conditions";
import { FormConfigApplicationStructureItem, FormConfigItem, FormItemType } from "../types/form";
import { Filter, FilterField } from "@smartsuite/types";

export type FieldVisibilityCallback = (fieldSlug: string, visitedFields?: string[]) => boolean;

export interface UseFormFieldVisibilityResult {
  checkFieldVisibility: FieldVisibilityCallback;
}

export const useFormFieldVisibility = (
  formItems: FormConfigItem[],
  formValues: FormikValues,
  appFieldMap: Map<string, FormConfigApplicationStructureItem>
): UseFormFieldVisibilityResult => {
  // Array containing all form fields and sections (and fields within it) to calculate
  // their conditions.
  const formConditionalItems = useMemo(() => {
    const items: FormConfigItem[] = [];
    formItems?.forEach((item) => {
      if (item.type === FormItemType.section) {
        items.push(item);
        items.push(...(item.params.items ?? []).filter((i) => i.type !== FormItemType.section));
      } else {
        items.push(item);
      }
    });
    return items;
  }, [formItems]);

  // This map contains the valid conditions for each field slug
  const validConditionsMap = useMemo(
    () =>
      formConditionalItems.reduce<Record<string, Filter | null>>((acc, item) => {
        acc[item.slug] = null;
        const conditions = item.params?.conditions;

        // If the rule is disabled or empty, then it won't got to the map
        if (!conditions?.enabled || !conditions?.object) return acc;

        // If the rule has no valid entries, then it won't got to the map
        const validConditions = cleanFilterRules(conditions.object.fields);
        if (!validConditions.length) return acc;

        // Other rules are added to the map for the field slug
        acc[item.slug] = { ...conditions.object, fields: validConditions };
        return acc;
      }, {}),
    [formConditionalItems]
  );

  const checkFieldVisibility = useCallback(
    (fieldSlug: string, visitedFields: string[] = []): boolean => {
      const allItems = formItems.reduce<FormConfigItem[]>((acc, item) => {
        if (item.type === FormItemType.section) {
          return acc.concat(item.params.items ?? []);
        }
        return acc.concat(item);
      }, []);

      // Return false if field has already been visited to prevent infinite recursion
      if (visitedFields.includes(fieldSlug)) {
        return false;
      }

      // Puts the current field in the list to prevent infinite recursion
      visitedFields.push(fieldSlug);

      const currentField = allItems.find((item) => item.slug === fieldSlug);

      if (currentField?.params?.hidden && visitedFields.length === 1) {
        return false;
      }

      // If field has no valid conditions to be parsed, then it's visible by default
      const validConditions = validConditionsMap[fieldSlug];

      const checkConditions = (fields: FilterField[], checkVisibility = false): boolean => {
        const checkField = (filterField: FilterField): boolean => {
          // Check if the field involved in the condition is visible
          if (!checkFieldVisibility(filterField.field, [...visitedFields])) {
            return false;
          }

          if (checkVisibility) {
            return checkFieldVisibility(filterField.field, [...visitedFields]);
          }

          const fieldValue = formValues[filterField.field];
          const fieldDefinition = appFieldMap.get(filterField.field);
          return isConditionSatisfiedMemoized(filterField, fieldValue, fieldDefinition, false);
        };

        if (!validConditions) return true;

        return validConditions.operator === "and"
          ? fields.every(checkField)
          : fields.some(checkField);
      };

      if (!validConditions) return true;

      return (
        checkConditions(validConditions.fields) && checkConditions(validConditions.fields, true)
      );
    },
    [appFieldMap, formItems, formValues, validConditionsMap]
  );

  return {
    checkFieldVisibility,
  };
};
