import { GeoAddressComponent, AddressFormValue, ParseAddressComponents } from "./address.types";
import { getCityFromGoogleMapsData } from "./city.helpers";
import { getStateFromGoogleMapsData } from "./state.helpers";

export const addressFieldValueToSysRoot = (value: AddressFormValue): string => {
  return [
    value?.location_address,
    value?.location_address2,
    value?.location_city,
    value?.location_state,
    value?.location_zip,
    value?.location_country,
  ]
    .filter((part) => !!part)
    .join(", ");
};

export const getParsedAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  preferLongCountryName = false
): ParseAddressComponents => {
  // Extracts from the array of address components the ones that are relevant for the
  // address, address2, city, state, zip code, and country fields.
  // Note that depending on the country and its structure, the results might come in
  // referencing different levels than the ones we're looking for.

  let newAddress;
  let newAddress2;
  let newZipCode;
  let newCountry;

  const components: GeoAddressComponent = {
    route: null,
    streetNumber: null,
    subpremise: null,
    locality: null,
    postalTown: null,
    admAreaLvl3: null,
    admAreaLvl2: null,
    admAreaLvl1: null,
    postalCode: null,
    country: null,
  };

  addressComponents.forEach((component) => {
    if (component.types.includes("route")) {
      components.route = component;
    } else if (component.types.includes("street_number")) {
      components.streetNumber = component;
    } else if (component.types.includes("subpremise")) {
      components.subpremise = component;
    } else if (component.types.includes("locality")) {
      components.locality = component;
    } else if (component.types.includes("postal_town")) {
      components.postalTown = component;
    } else if (component.types.includes("administrative_area_level_2")) {
      components.admAreaLvl2 = component;
    } else if (component.types.includes("administrative_area_level_1")) {
      components.admAreaLvl1 = component;
    } else if (component.types.includes("postal_code")) {
      components.postalCode = component;
    } else if (component.types.includes("country")) {
      components.country = component;
    }
  });

  if (components.route) {
    newAddress = `${components.streetNumber?.long_name ?? ""} ${components.route.long_name}`.trim();
    newAddress2 = components.subpremise?.long_name;
  }

  if (components.postalCode) newZipCode = components.postalCode.long_name;
  if (components.country)
    newCountry = preferLongCountryName
      ? components.country.long_name
      : components.country.short_name;

  const newCity = getCityFromGoogleMapsData(components);
  const newState = getStateFromGoogleMapsData(components);

  const parsedAddressComponents: ParseAddressComponents = {};

  if (newAddress) parsedAddressComponents.address = newAddress;
  if (newAddress2) parsedAddressComponents.address2 = newAddress2;
  if (newCity) parsedAddressComponents.city = newCity;
  if (newState) parsedAddressComponents.state = newState;
  if (newZipCode) parsedAddressComponents.zipCode = newZipCode;
  if (newCountry) parsedAddressComponents.country = newCountry;

  return parsedAddressComponents;
};
