import {
  countryBaseNameCodeMap,
  getUntranslatedCountryNameForCode,
} from "../../../utils/countriesList";
import { FieldRegistryConfig } from "../../types";
import { AddressFieldControl } from "./AddressFieldControl/AddressFieldControl";
import { addressFieldValueToSysRoot } from "./address.helpers";
import { AddressFieldValue, AddressFormValue } from "./address.types";

const defaultFieldsToDisplay = [
  "location_address",
  "location_address2",
  "location_city",
  "location_state",
  "location_zip",
  "location_country",
];

export const addressFieldConfig: FieldRegistryConfig<AddressFormValue, AddressFieldValue> = {
  icon: "address-field",
  control: AddressFieldControl,
  convertFormValueToApi: (value) => {
    const parsedValue = {
      ...value,
      location_country: getUntranslatedCountryNameForCode(value.location_country) ?? "",
    };

    return {
      ...parsedValue,
      sys_root: addressFieldValueToSysRoot(parsedValue),
      location_latitude: parsedValue?.location_latitude || "",
      location_longitude: parsedValue?.location_longitude || "",
    };
  },
  prefill: ({ value, field, config }) => {
    const displayFormat = field?.params.display_format;
    const addressFields = value.split(",");

    if (displayFormat === "single") {
      return {
        location_address: addressFields[0],
      };
    }

    const formField = config?.form_state.items.find((item) => item.slug === field?.slug);

    const displayFields =
      formField?.params?.display_fields?.sort(
        (a: string, b: string) =>
          defaultFieldsToDisplay.indexOf(a) - defaultFieldsToDisplay.indexOf(b)
      ) ?? defaultFieldsToDisplay;

    if (!displayFields) {
      return {};
    }

    return displayFields.reduce((acc: Record<string, string>, field: string, index: number) => {
      const addressField = addressFields[index];

      if (!addressField) {
        return acc;
      }

      if (field === "location_country") {
        const countryByNameOrCode = Object.values(countryBaseNameCodeMap).find(
          (countryBase) =>
            countryBase.label.defaultMessage.toLowerCase() === addressField.toLowerCase() ||
            countryBase.code === addressField.toUpperCase()
        );

        if (!countryByNameOrCode) {
          return acc;
        }

        return {
          ...acc,
          location_country: countryByNameOrCode.code,
        };
      }

      return {
        ...acc,
        [field]: addressField,
      };
    }, {});
  },
};
