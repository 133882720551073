import React, { useMemo } from "react";
import { defaultLogoUrl } from "../../helpers/defaultUrls";

import "./PageContentBox.sass";
import { FooterBranding } from "../FooterBranding/FooterBranding";

export interface PageContentBoxProps {
  logoUrl?: string | null;
  displayBranding?: boolean;
  children: React.ReactNode;
}

export const PageContentBox: React.FC<PageContentBoxProps> = ({
  logoUrl,
  displayBranding = false,
  children,
}) => {
  const isDefaultLogo = defaultLogoUrl === logoUrl;
  const linkUrl = isDefaultLogo ? "https://smartsuite.com" : undefined;

  const shouldHideLogo = useMemo(() => {
    if (!displayBranding && isDefaultLogo) {
      return true;
    }
    return shouldHideFormLogo();
  }, [displayBranding, isDefaultLogo]);

  return (
    <>
      <section className="page-content-box">
        {!shouldHideLogo && (
          <div className="page-content-box__logo">
            <a href={linkUrl} target="_blank" rel="noreferrer">
              {logoUrl && <img src={logoUrl} data-testid="logo" alt="SmartSuite logo" />}
            </a>
          </div>
        )}
        <div className="page-content-box__body">{children}</div>
      </section>
      {displayBranding && <FooterBranding />}
    </>
  );
};

function shouldHideFormLogo(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("header") === "false";
}
