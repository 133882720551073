/**
 * The Google Maps API provides location data differently based on regional addressing standards.
 * Some fields, such as `admAreaLvl1`, typically represent the **State/Province/Region**, while others
 * like `admAreaLvl2` may be relevant in certain countries as a fallback.
 *
 * General Rule for Determining the State Field:
 *
 *  1. **If `admAreaLvl1` exists, use it**
 *     - ✅ This typically represents the **State/Province/Region** in most countries.
 *     - Example (USA):
 *       ```json
 *       "admAreaLvl1": { "long_name": "California", "short_name": "CA" }
 *       ```
 *     - ✅ Use `"California"` as the state.
 *
 *  2. **If `admAreaLvl1` is missing, fallback to `admAreaLvl2`**
 *     - ✅ In some countries, `admAreaLvl2` might represent **a state-like region**.
 *     - Example (Brazil):
 *       ```json
 *       "admAreaLvl2": { "long_name": "Rio de Janeiro" }
 *       ```
 *     - ✅ Use `"Rio de Janeiro"` as the state.
 *
 *  3. **If both are missing, return `null`**
 *     - ❌ In rare cases, **Google Maps data may not provide state-related fields**.
 *     - The function will return `null` in such cases.
 *
 * **Why This Works Without Extra Conditions for Specific Countries:**
 * - In most cases, **Google always provides `admAreaLvl1` for states or regions**.
 * - Some countries (like Brazil) may rely on `admAreaLvl2` if `admAreaLvl1` is missing.
 *
 * How This Applies to Different Countries:
 *
 * 🇺🇸 **United States**
 *  - ✅ Uses `admAreaLvl1` for state (e.g., `"California"`).
 *  - ✅ If missing, falls back to `admAreaLvl2` (e.g., `"Los Angeles County"`).
 *
 * 🇬🇧 **United Kingdom**
 *  - ✅ Uses `admAreaLvl1` for **England, Scotland, Wales, or Northern Ireland**.
 *  - 🚨 **Do NOT use `admAreaLvl2` (e.g., "Greater London") as the state!**
 *
 * 🇸🇪 **Sweden**
 *  - ✅ Uses `admAreaLvl1` (County, "Län").
 *  - ✅ If missing, `admAreaLvl2` is used as a fallback.
 *
 * 🇧🇷 **Brazil**
 *  - ✅ Uses `admAreaLvl1` for states.
 *  - 🔄 If `admAreaLvl1` is missing, **fallback to `admAreaLvl2` (municipality)**.
 *
 * 🇮🇳 **India**
 *  - ✅ Uses `admAreaLvl1` for the **State**.
 */

import type { GeoAddressComponent } from "./address.types";

export const getStateFromGoogleMapsData = (data: GeoAddressComponent): string | null => {
  if (data.admAreaLvl1?.long_name) {
    return data.admAreaLvl1.long_name;
  } else if (data.admAreaLvl2?.long_name) {
    return data.admAreaLvl2.long_name;
  }
  return null;
};
