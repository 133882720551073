import React, { FC, Suspense } from "react";
import * as yup from "yup";
import { Checkbox } from "@smartsuite/react-ui/lib/components/forms/Checkbox/Checkbox";
import { useFormField } from "../../../hooks/useFormField";
import { FormConfigItem } from "../../../types/form";
import { validateWithYup } from "../../../registry/validator";
import { FieldVisibilityCallback } from "../../../hooks/useFormFieldVisibility";
import { validatorText } from "../../../registry/validator.text";
import { $t } from "../../../utils/intl";
import { ErrorBoundary } from "../../ErrorBoundary/ErrorBoundary";
import HTMLBlockSerializer from "../../FormHTMLBlockItem/HTMLBlockSerializer";
import { Paragraph } from "@smartsuite/react-ui/lib";

import "./FormConsentItem.sass";

interface FormConsentItemProps {
  item: FormConfigItem;
  checkFieldVisibility: FieldVisibilityCallback;
}

export const FormConsentItem: FC<FormConsentItemProps> = ({ item, checkFieldVisibility }) => {
  const isItemConditionSatisfied = checkFieldVisibility(item.slug);

  const formField = useFormField<{
    checked: boolean;
    shouldSkip: boolean;
  }>({
    name: item.slug,
    validate: validateWithYup(
      yup.object().when({
        is: () => isItemConditionSatisfied,
        then: (object) =>
          object
            .shape({
              checked: yup
                .boolean()
                .required($t(validatorText.validationRequired))
                .oneOf([true], $t(validatorText.validationRequired)),
              shouldSkip: yup
                .boolean()
                .required($t(validatorText.validationRequired))
                .oneOf([true], $t(validatorText.validationRequired)),
            })
            .required($t(validatorText.validationRequired)),
      })
    ),
  });

  if (!isItemConditionSatisfied) return null;

  const handleChange = (checked: boolean): void => {
    formField.onChange({
      checked,
      shouldSkip: true,
    });
  };

  const doc = item.params.doc;

  return (
    <div className="form-consent-item" data-field-name={item.slug}>
      <div className="form-consent-item__container">
        <Checkbox
          error={Boolean(formField.errorMessage)}
          checked={formField.value?.checked}
          onChange={handleChange}
        />
        <ErrorBoundary>
          <Suspense fallback={null}>
            <HTMLBlockSerializer doc={JSON.parse(doc)} />
          </Suspense>
        </ErrorBoundary>
      </div>
      {formField.errorMessage && (
        <div className="form-field-control__error-message">
          <Paragraph color="error" size="s">
            {formField.errorMessage}
          </Paragraph>
        </div>
      )}
    </div>
  );
};
