/**
 * The Google Maps API provides location data differently based on regional addressing standards.
 * Some fields, such as `postalTown`, are **only returned in specific countries**, while others
 * like `locality` are more universally present.
 *
 * This function follows a **prioritized approach** for determining the city:
 *
 *  1. **If `postalTown` exists, use it**
 *     - ✅ Used in **UK, Sweden, and some European countries** where postal towns
 *       might differ from the actual city.
 *     - ❌ **Not returned by Google Maps API for the US, Brazil, or India**.
 *
 *  2. **Else, if `locality` exists, use it**
 *     - ✅ Used in **US, Canada, India, Brazil, and most other countries**.
 *     - ❌ May be missing in countries that rely on `postalTown`.
 *
 *  3. **If neither is present, check `admAreaLvl2`**
 *     - ✅ Often represents a **county, district, or metro area** (useful in **Brazil, Germany,
 *       and some parts of the US**).
 *
 *  4. **If all else fails, use `admAreaLvl1`**
 *     - ✅ Represents a **state, province, or region**, useful as a **last fallback**.
 *
 * How This Applies to Different Countries:
 *
 * 🇺🇸 United States
 *  - **Google does NOT return `postalTown`**
 *  - ✅ Uses `locality` for the city.
 *
 * 🇬🇧 United Kingdom
 *  - ✅ Uses `postalTown` (e.g., `"postalTown": "Oxford"`).
 *  - Falls back to `locality` if missing.
 *
 * 🇸🇪 Sweden
 *  - ✅ Uses `postalTown` (e.g., `"postalTown": "Hässleholm"`).
 *  - Falls back to `locality` if missing.
 *
 * 🇧🇷 Brazil
 *  - **Google does NOT return `postalTown`**
 *  - ✅ Uses `locality`, falls back to `admAreaLvl2`.
 *
 * 🇮🇳 India
 *  - **Google does NOT return `postalTown`**
 *  - ✅ Uses `locality` for the city.
 */

import type { GeoAddressComponent } from "./address.types";

export const getCityFromGoogleMapsData = (data: GeoAddressComponent): string | null => {
  if (data?.postalTown?.long_name) {
    return data.postalTown.long_name;
  } else if (data?.locality?.long_name) {
    return data.locality.long_name;
  } else if (data?.admAreaLvl3?.long_name) {
    return data.admAreaLvl3.long_name;
  } else if (data?.admAreaLvl2?.long_name) {
    return data?.admAreaLvl2.long_name;
  } else if (data?.admAreaLvl1?.long_name) {
    return data.admAreaLvl1.long_name;
  }
  return null;
};
